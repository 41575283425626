
var $origin = $("#carouselReviews .carousel-inner").prop("outerHTML");
$(window).on( "load resize", function() {
	$( "#carouselReviews .carousel-inner" ).replaceWith( $origin );
	multiCarousel();
});
function multiCarousel(){
	if ( $( "#lg" ).is( ":visible" ) ) {
		do {
			$( "#carouselReviews .carousel-inner" ).children( ".carousel-grid:lt(4)" ).wrapAll( "<div class=\"carousel-item\"><div class=\"row\"></div></div>" );
			$( "#carouselReviews .carousel-inner .carousel-item:first" ).addClass("active");
		} while ( $( "#carouselReviews .carousel-inner" ).children( ".carousel-grid" ).length );
	} else if ( $( "#md" ).is( ":visible" ) ) {
		do {
			$( "#carouselReviews .carousel-inner" ).children( ".carousel-grid:lt(3)" ).wrapAll( "<div class=\"carousel-item\"><div class=\"row\"></div></div>" );
			$( "#carouselReviews .carousel-inner .carousel-item:first" ).addClass("active");
		} while ( $( "#carouselReviews .carousel-inner" ).children( ".carousel-grid" ).length );
	} else {
		do {
			$( "#carouselReviews .carousel-inner" ).children( ".carousel-grid:lt(1)" ).wrapAll( "<div class=\"carousel-item\"><div class=\"row\"></div></div>" );
			$( "#carouselReviews .carousel-inner .carousel-item:first" ).addClass("active");
		} while ( $( "#carouselReviews .carousel-inner" ).children( ".carousel-grid" ).length);
	}
}